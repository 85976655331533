/*!
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */
@use '@porsche-design-system/components-js/styles/scss' as pds;
@tailwind components;
@tailwind utilities;

.underline-link {
  text-decoration-line: underline !important;
}
.inactive-pure-link {
  pointer-events: none;
}
.inactive-pure-link a {
  color: #7f7f7f !important;
}

.border-radius-image {
  border-radius: pds.$pds-border-radius-large;
}

//These 2 styles are necessary to prevent an additional scroll behaviour
html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;

  // This is needed to have a good look & feel on a mobile device without additional heights added
  @include pds.pds-media-query-max('s') {
    position: relative;
  }
}
